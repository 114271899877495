import { Component, inject, Inject, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AGCFormComponent } from "../../components/form/form.component";
import { Country } from "../../interfaces/Country";
import { Office, OfficeDialogOptions, OfficeHour, OfficePhone, OfficeServiceEntry } from "../../interfaces/Office";
import { Region } from "../../interfaces/Region";
import { State } from "../../interfaces/State";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";
import { OfficeService } from "../../services/OfficeService";

type Mapper<T = any> = {
  [key: string]: T;
};

@Component({
  selector: 'app-dialog-office',
  templateUrl: 'office.dialog.html',
  styleUrls: ['office.dialog.scss'],
})
export class OfficeDialog {

  private readonly officeService = inject(OfficeService);
  private readonly messageService = inject(MessageService);
  private readonly dialogRef = inject(MatDialogRef);
  private readonly data = inject<OfficeDialogOptions>(MAT_DIALOG_DATA);

  loading: boolean = false;

  office?: Office;

  readonly form: FormGroup = new FormGroup({
    name: new FormControl(),
    email: new FormControl(),
    countryCode: new FormControl(),
    stateId: new FormControl(),
    regionId: new FormControl(),
    address1: new FormControl(),
    address2: new FormControl(),
    phoneList: new FormArray([]),
    hourList: new FormArray([]),
    serviceList: new FormGroup({}),
    coords: new FormControl(),
  });

  get phoneList() { return this.form.get('phoneList') as FormArray; }
  get hourList() { return this.form.get('hourList') as FormArray; }
  get serviceList() { return this.form.get('serviceList') as FormGroup; }

  get services() { return this.data.services; }

  readonly serviceMap: Mapper<OfficeServiceEntry> = {};

  country?: Country;
  state?: State;
  region?: Region;

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  constructor() {
    const { data, serviceMap, serviceList } = this;

    this.office = data.office as Office;

    let checkedServices: Mapper<OfficeServiceEntry> = {};

    if (data.office) {
      this.form.patchValue(data.office);
      this.patchPhones(data.office.phones);
      this.patchHours(data.office.hours);

      checkedServices = data.office.services.reduce((resp, item) => {
        resp[String(item.id)] = item;
        return resp;
      }, {} as Mapper<OfficeServiceEntry>);
    }

    for (const service of data.services) {
      const key = String(service.id);

      serviceMap[key] = service;

      const ctrl = new FormControl(key in checkedServices);
      serviceList.addControl(key, ctrl);
    }
  }

  private patchPhones(phones: OfficePhone[]) {
    const { phoneList } = this;

    phones.forEach(entry => {
      const ctrl = new FormControl(entry.phone);
      phoneList.push(ctrl);
    });
  }

  private patchHours(hours: OfficeHour[]) {
    const { hourList } = this;

    hours.forEach(entry => {
      const { startDayNum, stopDayNum, startTime, stopTime } = entry;
      const ctrl = new FormControl({ startDayNum, stopDayNum, startTime, stopTime });
      hourList.push(ctrl);
    });
  }

  addPhone() {
    const { phoneList } = this;
    phoneList.push(new FormControl());
  }

  addHours() {
    const { hourList } = this;
    hourList.push(new FormControl());
  }

  c(item: AbstractControl) { return item as FormControl; }

  submit() {
    const data: Office = this.form.value;

    if (this.office) {
      this.edit(data);
    } else {
      this.add(data);
    }
  }

  private add(data: Office) {
    const { officeService, messageService, dialogRef } = this;

    this.loading = true;

    officeService.add(data).subscribe({
      next: resp => {
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  private edit(data: Office) {
    const { office, officeService, messageService, dialogRef } = this;

    const id = office!.id!;

    this.loading = true;

    officeService.edit(id, data).subscribe({
      next: resp => {
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
