<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>{{ (admin) ? 'EDIT' : 'ADD NEW' }} ADMIN</h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <agc-form-field>
                <label for="">First Name <span class="text-danger">*</span></label>
                <input type="text" formControlName="firstName" class="form-control" required>
                <agc-message when="required">First Name is required.</agc-message>
              </agc-form-field>
            </div>

            <div class="col-md-6">
              <agc-form-field>
                <label for="">Last Name <span class="text-danger">*</span></label>
                <input type="text" formControlName="lastName" class="form-control" required>
                <agc-message when="required">Last Name is required.</agc-message>
              </agc-form-field>
            </div>
          </div>

          <agc-form-field>
            <label for="">Other Names</label>
            <input type="text" formControlName="otherNames" class="form-control">
          </agc-form-field>

          <agc-form-field>
            <label for="">Country <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="countryCode" [(data)]="country" required></agc-country-input>
            <agc-message when="required">Country is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province <span class="text-danger">*</span></label>
            <agc-state-input [countryCode]="country?.code" formControlName="stateId" [(data)]="state" required></agc-state-input>
            <agc-message when="required">State/Province is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Region/City/LGA <span class="text-danger">*</span></label>
            <agc-region-input [stateId]="state?.id" formControlName="regionId" required></agc-region-input>
            <agc-message when="required">Region/City/LGA is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 1 <span class="text-danger">*</span></label>
            <input type="text" formControlName="address1" class="form-control" required>
            <agc-message when="required">Address 1 is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 2</label>
            <input type="text" formControlName="address2" class="form-control">
          </agc-form-field>

          <agc-form-field>
            <label for="">Phone <span class="text-danger">*</span></label>
            <input type="text" formControlName="phone" class="form-control" required adminPhoneAvailable>
            <agc-message when="required">Phone number is required.</agc-message>
            <agc-message when="adminPhoneAvailable">An account with this phone already exists.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Email <span class="text-danger">*</span></label>
            <input type="text" formControlName="email" class="form-control" email adminEmailAvailable required>
            <agc-message when="required">Email address is required.</agc-message>
            <agc-message when="email">Email does not appear to be valid.</agc-message>
            <agc-message when="adminEmailAvailable">An account with this email already exists.</agc-message>
          </agc-form-field>
        </div>

        <div class="col-md-6">
          <agc-form-field>
            <label for="">Role <span class="text-danger">*</span></label>
            <select formControlName="roleId" class="form-control">
              <option [value]="null">--- Select Role ---</option>
              <option [value]="role.id" *ngFor="let role of data.roles">{{ role.name }}</option>
            </select>
            <agc-message when="required">Role is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Username <span class="text-danger">*</span></label>
            <input type="text" formControlName="username" class="form-control" required username adminNameAvailable>
            <agc-message when="required">Username is required.</agc-message>
            <agc-message when="username">Username does not appear to be valid.</agc-message>
            <agc-message when="adminNameAvailable">An account with this username already exists.</agc-message>
          </agc-form-field>

          <div class="mt-5">
            <hr>

            <agc-form-field>
              <label for="">Password <span class="text-danger" *ngIf="!admin">*</span></label>
              <input type="password" formControlName="password" class="form-control" [required]="!admin" [placeholder]="(admin) ? 'Leave blank to ignore password' : ''">
              <agc-message when="required">Password is required.</agc-message>
            </agc-form-field>
          </div>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Submit</button>
  </mat-dialog-actions>
</agc-container>
