<div id="pcoded" class="pcoded" #pcodedRef nav-type="st6" theme-layout="vertical" vertical-placement="left" vertical-layout="wide" pcoded-device-type="desktop" vertical-nav-type="expanded" vertical-effect="shrink" vnavigation-view="view1" fream-type="theme1" sidebar-img="false" sidebar-img-type="img1" layout-type="light">
  <!-- Desktop | pcoded-device-type="desktop" vertical-nav-type="expanded" vertical-effect="shrink" -->
  <!-- Mobile | pcoded-device-type="phone" vertical-nav-type="offcanvas" vertical-effect="overlay" -->
  <div class="pcoded-overlay-box" (click)="toggleMenu($event)"></div>
  <div class="pcoded-container navbar-wrapper">

    <nav class="navbar header-navbar pcoded-header" [attr.header-theme]="theme" pcoded-header-position="fixed">
      <div class="navbar-wrapper">

        <div class="navbar-logo" logo-theme="theme1">
          <a class="mobile-menu" id="mobile-collapse" href="javascript:;" (click)="toggleMenu($event)">
            <i class="feather icon-menu" [ngClass]="{'icon-toggle-right': !showMenu, 'icon-toggle-left': showMenu}"></i>
          </a>
          <a routerLink="/home">
            <img class="img-fluid" src="/assets/images/logo-md.png" alt="AGC Courier">
          </a>
          <a class="mobile-options" href="javascript:;" (click)="toggleNavRight()">
            <i class="feather icon-more-horizontal"></i>
          </a>
        </div>

        <div class="navbar-container container-fluid">
          <ul class="nav-left">
            <li hidden>
              <a class="mobile-menu" id="mobile-collapse" href="">
                <i class="feather icon-menu"></i>
              </a>
            </li>
            <li>
              <a href="" (click)="fullScreen($event)">
                <i class="feather icon-maximize full-screen"></i>
              </a>
            </li>
          </ul>
          <ul class="nav-right" #navRightRef>
            <li class="header-notification" *ngIf="false">
              <div class="dropdown-primary dropdown">
                <div class="dropdown-toggle" data-toggle="dropdown">
                  <i class="feather icon-bell"></i>
                  <span class="badge bg-c-pink">5</span>
                </div>
                <ul class="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                  <li>
                    <h6>Notifications</h6>
                    <label class="label label-danger">New</label>
                  </li>
                  <li>
                    <div class="media">
                      <img class="d-flex align-self-center img-radius" src="https://www.gravatar.com/avatar/?d=mp&s=160&f=y" alt="Generic placeholder image">
                      <div class="media-body">
                        <h5 class="notification-user">John Doe</h5>
                        <p class="notification-msg">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                        <span class="notification-time">30 minutes ago</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            @if (showWallet) {@if (wallet | async; as w) {
              <li class="header-notification">
                <div class="dropdown-primary dropdown">
                  <div class="dropdown-toggle" (click)="openWallet()">
                    <i class="icofont icofont-wallet"></i>
                    <span>&nbsp;&raquo;&nbsp;</span>
                    <agc-amount [currency]="w.currency">
                      <span>{{ w.balance | number:'1.2-2' }}</span>
                    </agc-amount>
                  </div>
                </div>
              </li>
            }}

            @if (canShowMore) {
              <li class="header-notification">
                <div class="dropdown-primary dropdown">
                  <div class="dropdown-toggle" data-toggle="dropdown">
                    <i class="icofont icofont-navigation-menu"></i>
                  </div>
                  <ul class="show-notification profile-notification more-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                    @if (showCreateShipment) {
                      <li>
                        <a href="javascript:;" (click)="addNewShipment()">
                          <i class="feather icon-plus"></i> New Shipment
                        </a>
                      </li>
                    }
                    @if (showDownloadScanner) {
                      <li>
                        <a href="javascript:;" (click)="downloadScanner($event)">
                          <!-- <i class="feather icon-download"></i> Parcel Scanner -->
                          <i class="icofont icofont-bar-code"></i> Parcel Scanner
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              </li>
            }

            <li class="user-profile header-notification">
              <div class="dropdown-primary dropdown">
                <div class="dropdown-toggle" data-toggle="dropdown">
                  <img src="https://www.gravatar.com/avatar/?d=mp&s=160&f=y" class="img-radius" alt="User-Profile-Image">
                  <span>{{ account | fullname }}</span>
                  <i class="feather icon-chevron-down"></i>
                </div>
                <ul class="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                  <li>
                    <a routerLink="/home/profile">
                      <i class="feather icon-user"></i> Profile
                    </a>
                  </li>
                  <li>
                    <a routerLink="/home/settings">
                      <i class="feather icon-settings"></i> Settings
                    </a>
                  </li>
                  <li>
                    <a href="" (click)="logout($event)">
                      <i class="feather icon-log-out"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Sidebar chat start -->
    <div id="sidebar" class="users p-chat-user showChat">
      <div class="had-container">
        <div class="card card_main p-fixed users-main">
          <div class="user-box">
            <div class="chat-inner-header">
              <div class="back_chatBox">
                <div class="right-icon-control">
                  <input type="text" class="form-control  search-text" placeholder="Search Friend" id="search-friends">
                  <div class="form-icon">
                    <i class="icofont icofont-search"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-friend-list">
              <div class="media userlist-box" data-id="1" data-status="online" data-username="Josephin Doe" data-toggle="tooltip" data-placement="left" title="Josephin Doe">
                <a class="media-left" href="">
                  <img class="media-object img-radius img-radius" src="/assets/images/avatar-3.jpg" alt="Generic placeholder image ">
                  <div class="live-status bg-success"></div>
                </a>
                <div class="media-body">
                  <div class="f-13 chat-header">Josephin Doe</div>
                </div>
              </div>
              <div class="media userlist-box" data-id="2" data-status="online" data-username="Lary Doe" data-toggle="tooltip" data-placement="left" title="Lary Doe">
                <a class="media-left" href="">
                  <img class="media-object img-radius" src="/assets/images/avatar-2.jpg" alt="Generic placeholder image">
                  <div class="live-status bg-success"></div>
                </a>
                <div class="media-body">
                  <div class="f-13 chat-header">Lary Doe</div>
                </div>
              </div>
              <div class="media userlist-box" data-id="3" data-status="online" data-username="Alice" data-toggle="tooltip" data-placement="left" title="Alice">
                <a class="media-left" href="">
                  <img class="media-object img-radius" src="/assets/images/avatar-4.jpg" alt="Generic placeholder image">
                  <div class="live-status bg-success"></div>
                </a>
                <div class="media-body">
                  <div class="f-13 chat-header">Alice</div>
                </div>
              </div>
              <div class="media userlist-box" data-id="4" data-status="online" data-username="Alia" data-toggle="tooltip" data-placement="left" title="Alia">
                <a class="media-left" href="">
                  <img class="media-object img-radius" src="/assets/images/avatar-3.jpg" alt="Generic placeholder image">
                  <div class="live-status bg-success"></div>
                </a>
                <div class="media-body">
                  <div class="f-13 chat-header">Alia</div>
                </div>
              </div>
              <div class="media userlist-box" data-id="5" data-status="online" data-username="Suzen" data-toggle="tooltip" data-placement="left" title="Suzen">
                <a class="media-left" href="">
                  <img class="media-object img-radius" src="/assets/images/avatar-2.jpg" alt="Generic placeholder image">
                  <div class="live-status bg-success"></div>
                </a>
                <div class="media-body">
                  <div class="f-13 chat-header">Suzen</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar inner chat start-->
    <div class="showChat_inner">
      <div class="media chat-inner-header">
        <a class="back_chatBox">
          <i class="feather icon-chevron-left"></i> Josephin Doe
        </a>
      </div>
      <div class="media chat-messages">
        <a class="media-left photo-table" href="">
          <img class="media-object img-radius img-radius m-t-5" src="https://www.gravatar.com/avatar/?d=mp&s=160&f=y" alt="Generic placeholder image">
        </a>
        <div class="media-body chat-menu-content">
          <div class="">
            <p class="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
            <p class="chat-time">8:20 a.m.</p>
          </div>
        </div>
      </div>
      <div class="media chat-messages">
        <div class="media-body chat-menu-reply">
          <div class="">
            <p class="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
            <p class="chat-time">8:20 a.m.</p>
          </div>
        </div>
        <div class="media-right photo-table">
          <a href="">
            <img class="media-object img-radius img-radius m-t-5" src="/assets/images/avatar-4.jpg" alt="Generic placeholder image">
          </a>
        </div>
      </div>
      <div class="chat-reply-box p-b-20">
        <div class="right-icon-control">
          <input type="text" class="form-control search-text" placeholder="Share Your Thoughts">
          <div class="form-icon">
            <i class="feather icon-navigation"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar inner chat end-->
    <div class="pcoded-main-container" style="margin-top: 56px;">
      <div class="pcoded-wrapper">
        <nav class="pcoded-navbar" [ngClass]="{'show-menu': showMenu}" agc-mousewheel navbar-theme="theme1" active-item-theme="theme1" sub-item-theme="theme2" active-item-style="style0" pcoded-navbar-position="fixed">
          <div class="pcoded-inner-navbar main-menu">
            <div class="pcoded-navigatio-lavel" menu-title-theme="theme5">{{ name }}</div>

            <ul class="pcoded-item pcoded-left-item">
              <li [routerLinkActive]="activeClass(menu)" [ngClass]="{'pcoded-hasmenu' : menu.children?.length}" dropdown-icon="style1" subitem-icon="style1" *ngFor="let menu of menuList">
                <a [routerLink]="menu.link">
                  <span class="pcoded-micon">
                    <i [ngClass]="menu.icon"></i>
                  </span>
                  <span class="pcoded-mtext">{{ menu.text }}</span>
                </a>
                <ul class="pcoded-submenu" *ngIf="menu.children?.length">
                  <li routerLinkActive="active" *ngFor="let child of menu.children">
                    <a [routerLink]="child.link">
                      <span class="pcoded-mtext">{{ child.text }}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>

        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <!-- Start: Page header -->
                <div class="page-header" *ngIf="title">
                  <div class="page-header-title">
                    <div class="d-inline">
                      <h4>{{ title }}</h4>
                      <!-- <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span> -->
                    </div>
                  </div>
                </div>
                <!-- End: Page header -->

                <!-- Start: Page body -->
                <div class="page-body">
                  <ng-content></ng-content>
                </div>
                <!-- End: Page body -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
