<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>INVOICE #{{ invoice.id }}</h1>

  <mat-dialog-content class="mat-typography">
    <iframe #iframe frameborder="0"></iframe>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Close</button>

    <!-- @if (isReady && !isAdmin) { -->
    @if (isReady) {
      <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="submit()">Make Payment</button>
    }
  </mat-dialog-actions>
</agc-container>
